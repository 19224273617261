@import '../../../../common/sass/theme/variables';

.sign-up-responsive {
    max-width: 100%;
    overflow: auto;
    @media (max-width: $breakpoints-sm) {
        padding: 50px 0;
        height: fit-content;
    }
    @media (max-width: 280px) {
        padding: 100px 0;
        height: fit-content;
        .backBtn {
            font-size: 14px;
            margin-top: 10px;
            width: 100% !important;
            display: inline-block;
            align-items: center;
            padding: 20px;
            transform: translateX(10%);
        }
    }
    .input-select {
        border-radius: 10px;
        background-color: $input-background;
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
        &:focus {
            background-color: $input-background;
        }
    }
    .form-control {
        color: $steel-blue;
        border-radius: 10px;
    }
    .top-space {
        margin-top: 4vh;
        min-width: 60%;
    }
    .dropdownForm {
        color: $steel-blue !important;
    }
}
