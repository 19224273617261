@import '../../common/sass/theme/variables';

.loading {
    margin-bottom: 2px;
}
.registerBtn {
    margin-top: 50px;
    border-radius: 10px !important;
    background-color: $primary !important;
}
.btn-block {
    margin-top: 50px;
    border-radius: 10px !important;
    background-color: $primary !important;
}

.switch-button {
    width: 50px;
    height: 24px;
    background-color: #ffffff;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.switch-button.on {
    background-color: #ff9900;
}

.switch-button.off {
    background-color: #ccc;
}

.slider {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
}

.switch-button.on .slider {
    transform: translateX(30px);
}
