@import '../../../../common/sass/theme/variables.scss';

.option {
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 0.8rem 0 0.8rem 0.5rem;
    cursor: pointer;

    &__icon {
        width: 1rem;
        height: 1rem;
        img {
            position: relative;
            top: -5px;
            width: 100%;
            object-fit: contain;
        }
    }
    &__text {
        font-size: 1rem;
        &-editor {
            color: $white;
        }
        &-doctor {
            color: $text-extra-color;
        }
        &--active {
            color: $warning !important;
        }
    }
}
.option-dropdown {
    .nested-options {
        margin-left: 1rem;
        max-height: 0;
        transition: max-height 0.15s ease-out;
        overflow: hidden;
        &__text {
            margin-left: 1.5rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            box-sizing: border-box;
            padding: 0.8rem 0 0.8rem 10px;
            width: 18rem;
            height: auto;

            .option__icon img {
                width: 24px !important;
                height: 21px !important;
                object-fit: scale-down;
                padding: 2px;
                background: #fff;
                box-shadow: 1px 1px 1px 1px $box-shadow;
                border-radius: 5px;
            }
            .option--chosen1 {
                font-family: 'Open Sans Bold' !important;
                background-color: $chosen-option-sidebar;
                transition: 0.2s;
            }

            @media (max-width: $breakpoints-sm) {
                width: 14rem;
            }
        }
        &--show {
            max-height: 8rem;
            transition: max-height 0.15s ease-in;
        }
        &--show-3 {
            max-height: 9rem;
            transition: max-height 0.15s ease-in;
        }
    }
}
.option--chosen {
    font-family: 'Open Sans Bold' !important;
    border-radius: 10px;
    background-color: $chosen-option-sidebar;
    transition: 0.2s;
}
.option-nested-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    &-editor {
        color: $white !important;
    }
    &-doctor {
        color: $text-extra-color !important;
    }
    .fa-caret-up:before,
    .fa-caret-down:before {
        padding-left: 40px;
        margin-left: 40px;
    }

    @media (max-width: $breakpoints-sm) {
        .fa-caret-up:before,
        .fa-caret-down:before {
            padding-left: 10px;
            margin-left: 10px;
        }
    }
}
