@import '../../../../../common/sass/theme/variables';

.upload-file-modal {
    .upload-file {
        .drop-area {
            width: 20rem;
            height: 15rem;
            margin: auto;
            margin-bottom: 1rem;
            border: 4px dashed $grey-border;
            background-color: $silver-gray;
            text-align: center;
            padding-top: 8%;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            p {
                display: flex;
                justify-content: center;
                color: $black;
                font-size: 1rem;
                font-weight: 600;
            }
            .button-upload {
                display: inline-block;
                margin-top: 1rem;
                font-size: 1rem;
                background-color: $blue-jive;
                color: $white;
                padding: 0.8rem 1.5rem;
                border-radius: 5px;
            }
        }
        .files-area {
            border-top: 2px solid $light-grey2;
            border-bottom: 2px solid $light-grey2;
            margin-top: 1.5rem;
            padding: 1rem 0;
            .file {
                margin: 5px;
                display: flex;
                align-items: center;
                span {
                    display: inline-block;
                    line-height: 20px;
                    background-color: $silver-gray;
                    padding: 0.5rem;
                    margin-left: 0.5rem;
                    img {
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 1rem 1.5rem;
        .add-button {
            font-size: 15px !important;
            background-color: $blue-jive;
        }
    }
}
+ .attach-transcription {
    width: 100% !important;
    @media (max-width: $breakpoints-xxl) {
        max-width: 100%;
        min-width: unset;
    }
}
