@import '../../../common/sass/theme/_variables';

.status-text {
    pointer-events: none;
    background-image: unset !important;
    background-size: unset !important;
    background-repeat: unset !important;
    background-position: unset !important;
    select {
        text-align-last: center;
    }
}
.submitted-select {
    text-align: center;
    color: #001537 !important;
    background-color: #fff3b2 !important;
}
.in-progress-select {
    background-color: #08e174 !important;
    color: #001537 !important;
}

.container {
    background-color: #f5f9fd;
    max-width: 100% !important;
    padding: 0 5%;
}
.empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .empty-element {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 40%;
        .empty-image {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
            margin-bottom: 50px;
        }
        p {
            font-size: 18px;
        }
    }
}
