@import '../../../../common/sass/theme/variables';

.header-historical {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .download-log {
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 50px;
        .download-log-title {
            font-size: 24px;
            font-family: 'Century Gothic Bold';
            color: #4a86e8;
        }
    }
    @media (max-width: $breakpoints-md) {
        flex-direction: column;
        .download-log {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 400px) {
        .download-log-title,
        .title-page {
            font-size: 20px !important;
        }
        .title-page {
            min-width: 15rem !important;
        }
    }
}

.td-line-height {
    td {
        line-height: 1.5rem !important;
    }
}
.finished-select {
    text-align: center;
    color: #001537 !important;
    background-color: #08e174 !important;
}
.submitted-select {
    color: #001537 !important;
    background-color: #fff3b2 !important;
}
.folder-icon {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .files-text {
        font-size: 12px;
        color: #6a7891;
        font-family: 'SF UI Text Regular Italic';
    }
    img {
        width: 25px;
        height: 20px;
        object-fit: scale-down;
    }
    .downloadIcon {
        position: relative;
    }
}
.menu-download-logs::-webkit-scrollbar {
    display: none;
}
.menu-download-logs {
    max-height: 100vh;
    overflow-y: auto;
    color: #001537;
    display: none;
    width: 22rem;
    height: 100vh;
    background-color: #fff;
    box-shadow: 1px 0 0 0 $box-shadow;
    font-size: 16px;
    position: absolute;
    right: 0;
    z-index: 9;
    transition: transform 0.2s ease;
    will-change: transform;
    transform: translateX(22rem);
    flex-direction: column;
    margin: 0 auto;
    .menu-download-logs-items {
        margin-left: 6%;
        margin-right: 12%;
    }
    @media (max-width: $breakpoints-sm) {
        width: 18rem;
    }
    .download-log {
        margin: 10px auto;
    }
}
.isShow {
    display: block !important;
    transform: translateX(0);
}

#basic-menu {
    transform: translateX(-3rem);
    .MuiPaper-root {
        width: 400px;
        max-height: 300px;
        overflow-y: auto;
        margin-top: 15px !important;
        border-radius: 15px !important;
        transform: translateX(-17px) !important;
        ul > li:hover {
            color: #fff;
            background-color: $primary;
        }
        img {
            width: 25px;
            height: 20px;
            object-fit: scale-down;
        }
        .item-download {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            gap: 10px;
        }
        .dictations {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
            }
            .dictationsHeaderText {
                font-size: 14px;
                color: #6a7891;
            }
        }
        .dictationsIcon {
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .closeIcon {
                cursor: pointer;
            }
        }
        .fileDownload {
            width: 40%;
            display: flex;
            flex: 1;
            gap: 0.5rem;
            font-size: 14px;
            color: #001537;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            img {
                width: 15px;
                height: 20px;
                object-fit: cover;
            }
        }
        .txtProgress {
            font-size: 12px;
            flex: 1;
            &--downloading {
                color: #ff9900;
            }
            &--downloaded {
                color: #6a7891d9;
                font-style: italic;
            }
            @media (max-width: $breakpoints-sm) {
                display: none;
            }
        }
    }
    @media (max-width: 576px) {
        transform: translateX(-1rem);
        .MuiPaper-root {
            width: 300px;
        }
    }
}
.button-row {
    background-color: #fff !important;
    border: none !important;
    &:hover {
        background-color: #f7f7f7 !important;
    }
}
.iconDownloaded {
    width: 25px;
    height: 25px;
    align-items: center;
}

.download-items-container {
    padding: 10px 22px;
    border: 1px solid #6a7891;
    border-radius: 10px;
    color: #001537;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .download-items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .file-icon {
            width: 15px;
            height: 20px;
        }
        .title-file-download {
            display: flex;
            flex-direction: column;
            .file-id {
                font-size: 16px;
            }
            .file-name {
                font-size: 12px;
            }
        }
    }
    .download-icon {
        width: 25px;
        height: 25px;
    }
}
