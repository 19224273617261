@import '../../../../../common/sass/theme/variables';

.modal-content {
    box-shadow: 5px 5px 7px 0 $box-shadow;
    .modal-header {
        color: $white;
        .modal-title {
            font-weight: 700;
        }
        button {
            span {
                color: $white;
            }
        }
    }

    .model-add {
        background-color: $blue-jive;
    }

    .modal-body {
        padding-bottom: 0;
        .label-add-assignee {
            font-size: 15px !important;
            font-weight: 700;
            color: $background-dashboard;
        }
        p {
            font-size: 1.1rem;
            color: $black;
        }
        #yes-radio,
        #no-radio {
            margin-top: 0 !important;
        }
    }
    .modal-footer {
        border: none;
        .cancel-button {
            color: $blue-jive;
            border-color: $blue-jive;
            font-size: 15px !important;
            &:hover {
                background-color: $blue-jive;
                color: $white;
            }
        }
        .save-button {
            background-color: $blue-jive;
            font-size: 15px !important;
            &:hover {
                background-color: $button-hover;
            }
        }
    }
}
