@import '../../../../common/sass/theme/variables';

.header-files-list {
    display: flex;
    gap: 10px;
    align-items: center;
    @media (max-width: $breakpoints-sm) {
        flex-direction: column;
        align-items: start;
        gap: 0;
        margin-bottom: 10px;
    }
    span {
        font-size: 16px;
        color: #6a7891;
        font-family: 'SF UI Text Regular Italic';
    }
}

.td-line-height {
    td {
        line-height: 1.5rem !important;
    }
}
.finished-select {
    text-align: center;
    color: #001537 !important;
    background-color: #08e174 !important;
}
.submitted-select {
    color: #001537 !important;
    background-color: #fff3b2 !important;
}

.cancel-select {
    color: #001537 !important;
    text-align: center;
}

.iconDownloaded {
    width: 25px;
    height: 25px;
    align-items: center;
}

.fileDataName {
    display: flex !important;
    align-items: center !important;
    gap: 13px !important;
    img {
        width: 15px;
        height: 20px;
        object-fit: cover;
    }
}

.item-download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-right: 15px;

    .txtProgress {
        font-size: 12px;
        flex: 1;
        &--downloading {
            color: #ff9900;
        }
        &--downloaded {
            color: #6a7891d9;
            font-style: italic;
        }
        @media (max-width: $breakpoints-sm) {
            display: none;
        }
    }

    img {
        width: 25px;
        height: 25px;
        object-fit: cover;
    }
}
