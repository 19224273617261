@import '../../../common/sass/theme/variables';

.main-content {
    background-color: $white;
    width: 100%;
    padding: 1rem 3rem 8rem 3rem;
    box-shadow: 0px -1px 5px -2px $box-shadow;
    border-radius: 5px;
    @media (max-width: $breakpoints-sm) {
        padding: 1rem 1rem 8rem 1rem;
        border-radius: 15px;
    }
    &__block {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        @media (max-width: $breakpoints-lg) {
            flex-direction: column;
        }
        .title {
            max-width: 30%;
            min-width: 13rem;
            color: $steel-blue;
            font-size: 18px;
        }
        .content {
            width: 70%;
            @media (max-width: $breakpoints-lg) {
                width: 100%;
            }
            .text-area {
                width: 100%;
                height: 60px;
                border: 1px solid $grey-border;
                padding: 0.5rem;
                border-radius: 10px;
                resize: unset;
                &:focus {
                    outline: none;
                }
            }
        }
    }
    .upload-dictation-btn {
        font-family: 'Verdana Bold' !important;
        border-radius: 10px;
        padding: 14px 25px !important;
        background: #4a86e8 !important;
        font-size: 18px !important;
    }
    .blue-button {
        font-family: 'Open Sans Bold', sans-serif !important;
        float: right;
        padding: 0.5rem 2rem;
        background-color: $blue-jive;
        color: $white;
        border: none;
        font-size: 24px;
        @media (max-width: $breakpoints-lg) {
            font-size: 20px;
            width: 100% !important;
        }
        &:hover {
            background-color: $button-hover !important;
        }
    }
}
.empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .empty-element {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 40%;
        .empty-uploadDictation {
            width: 70%;
            height: 50%;
            object-fit: cover;
            margin-bottom: 50px;
        }
        p {
            font-size: 18px;
        }
        @media (max-width: $breakpoints-sm) {
            max-width: 60%;
            .empty-uploadDictation {
                width: 100%;
            }
        }
        .upload-btn {
            font-family: 'Verdana Bold';
            margin-top: 20px;
            padding: 14px 42px;
            border-radius: 10px;
            background-color: $primary;
            color: white;
            font-size: 18px;
            border: none;
            text-align: center;
            @media (max-width: $breakpoints-sm) {
                padding: 5px 100px;
                font-size: 16px;
                display: block;
            }
        }
    }
}
