@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Variables
$auth-title: #e66a04;
$auth-pw-rules-title: #3d78c9;
$auth-submit: #0250b2;
$auth-submit-hover: #0250b2e3;
$notification-success: #28a745;
$notification-info: #17a2b8;
$notification-warning: #ffc107;
$notification-error: #d5080f;
$orange-jive: #ef6003;
$blue-jive: #0050b0;
$button-hover:#386CBF;
$box-shadow: rgba(0, 0, 0, 0.4);
$input-background: #fff;
$background-page: #efefef;
$border-table: #dee2e6;
$background-dashboard: #162e4a;
$chosen-option-sidebar: #fff5e5;
$content-layout-background: #efefef;
$background-collapse-table: #EFEFF0;
$text-extra-color: #001537;
$background-in-progress-select: #d7f0db;
$background-finished-select: #cce9da;
$background-submitted-select: #ffffcd;
$background-unassigned-select: #ebebeb;
$background-cancel-select: #f3d4d5;
$layer-shadow: #0000005c;
$warning: #ff9900;
// Color system
$black: #000000;
$black2: #0a0c13;
$white: #ffffff;
$error: #dc3545;
$grey: #adadad;
$sliver: #808080;
$sliver2: #999;
$light-grey: #f2f2f2;
$light-grey2: #ddd;
$light-sliver: #f5f5f5;
$light-sliver2: #f7f7f7;
$light-sliver3: #ededed;
$separator-border-color: #ebedf3;
$separator-border-color2: #e7e7e7;
$title-bg-color: #222;
$silver-gray: #e6e6e6;
$silver-gray2: #ced4da;
$gray: #c4c4c4;
$dark-gray: #8e8e8e;
$dark-gray2: #acaaa6;
$black2: #171717;
$black-border: #212529;
$grey-border: #B5BEC6;
$light-black: #636363;
$light-black2: #878787;
$light-white: #f1f1f1;
$bg-icon: #ececec;
$primary: #4a86e8;
$green: #28a745;
$blue-light: #7da5d6;
$dodger-blue: #3ea8f7;
$blue-bold: #0050b1;
$steel-blue: #6a7891;
$cornflower-blue: #5c7095;
$box-shadow: #0000001f;
$midnight-blue: #051a3b;
// breakpoints
$breakpoints-xs: 0;
$breakpoints-sm: 576px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1200px;
$breakpoints-xxl: 1440px;
