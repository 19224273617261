@import '../../../../../common/sass/theme/variables';

.files-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    @media (max-width: $breakpoints-lg) {
        flex-direction: column;
    }
    &__title {
        text-align: center;
        max-width: 25%;
        margin-right: 5%;
        img {
            width: 32px;
            height: 40px;
            object-fit: scale-down;
        }
        .choose-file {
            font-family: 'Adobe Clean Bold';
            font-size: 18px;
            font-weight: 700;
        }
        @media (max-width: $breakpoints-lg) {
            max-width: 100%;
        }
        min-width: 13rem;
        .text-color-orange {
            color: $orange-jive;
            font-size: 1rem;
        }
        .black-text {
            font-size: 18px;
            color: #040f1d;
        }
    }
    &__dropzone {
        flex-direction: column;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $breakpoints-lg) {
            width: 100%;
        }
        .drop-area {
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 11rem;
            margin: auto;
            margin-bottom: 1rem;
            border: 2px dashed $grey-border;
            border-radius: 15px;
            text-align: center;
            cursor: pointer;
            &:focus {
                outline: none;
            }
            p {
                color: $black;
                font-size: 0.9rem;
                b {
                    font-size: 1rem;
                    color: $primary !important;
                }
                span {
                    color: $blue-jive;
                }
            }
            .choose-file-btn {
                font-family: 'Verdana Bold' !important;
                padding: 14px 53px;
                border-radius: 10px;
                background: white;
                border: 1px solid $primary;
                color: $primary;
                font-weight: 700;
                font-size: 18px;
            }
            .drag-file {
                b {
                    font-family: 'Adobe Clean Bold' !important;
                }
            }
        }
        .files-area {
            width: 100%;
            &__file {
                padding: 5px 0;
                font-family: 'Tahoma Regular';
                color: #001537;
                margin: 5px;
                display: flex;
                align-items: center;
                span {
                    max-width: 50%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    border-radius: 5px;
                    display: inline-block;
                    line-height: 20px;
                    background-color: $silver-gray;
                    padding: 0.5rem;
                    margin-left: 0.5rem;
                    margin-right: auto;
                    img {
                        margin-right: 0.5rem;
                        position: relative;
                        top: -2px;
                    }
                }
                .delete-icon {
                    cursor: pointer;
                    padding: 3px;
                }
            }
        }
    }
}
