@import '../../../../common/sass/theme/variables.scss';

.option {
    width: 90%;
    display: flex;
    align-items: center;
    padding: 0.8rem 0.8rem 0.8rem 0.5rem;
    gap: 10px;
    cursor: pointer;

    &__icon {
        width: 1.5rem !important;
        img {
            padding: 2px;
            position: relative;
            top: -5px;
            width: 100%;
            object-fit: contain;
        }
    }
    &__text {
        font-size: 1rem;

        &-editor {
            color: $white !important;
        }
        &-doctor {
            color: $text-extra-color;
        }
    }
}
.text-active {
    color: $warning !important;
}
.option--chosen {
    font-family: 'Open Sans Bold' !important;
    background-color: $chosen-option-sidebar;
    transition: 0.2s;
    .option__icon img {
        width: 2rem;
        height: 2rem;
        padding: 2px;
        background: #fff;
        box-shadow: 1px 1px 1px 1px $box-shadow;
        border-radius: 5px;
    }
}
