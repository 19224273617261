@import '../../../../common/sass/theme/variables';

.search-component {
    .input-group {
        min-width: 300px;
    }
    font-size: 15px !important;
    border-radius: 10px !important;
    button {
        background-color: $white;
        border: 1px solid $silver-gray2;
        font-size: 15px !important;
        font-weight: 700;
        color: $background-dashboard;
    }
    .text-truncate {
        border-radius: 10px;
    }
    .search-btn {
        border-radius: 10px;
        background-color: $primary;
        color: white;
        font-size: 16px !important;
        font-weight: 100;
    }
    .search-download-log {
        background-color: #fff !important;
        color: #000;
    }
}
