@import '../../../../common/sass/theme/variables';

.inactive-account {
    color: $error;
    font-weight: bold;
}

.active-account {
    color: $auth-submit;
    font-weight: bold;
}
