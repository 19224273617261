@import '../../common/sass/theme/variables';

.page-container {
    display: flex;
    min-height: 100vh;
    .page-background {
        width: 70%;
        height: 100vh;
        position: relative;
        @media (max-width: $breakpoints-xl) {
            display: none;
        }
        .image-background {
            width: 100%;
            height: 100vh;
            z-index: -1;
            position: absolute;
        }
        .information {
            overflow: hidden;
            padding: 4.8% 4.3%;
            &__header {
                display: flex;
                justify-content: space-between;
            }
            &__logo {
                display: flex;
                overflow: hidden;
                margin-top: 10px;
                .logo {
                    height: 60px;
                    overflow: hidden;
                    img {
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                    }
                }
                .web-name {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: space-around;
                    height: 65px;
                    &__title {
                        font-family: sans-serif;
                        color: var($steel-blue);
                        font-size: 22px;
                        width: 150px;
                        letter-spacing: 2px;
                        font-weight: bold;
                    }
                    &__start {
                        width: 150px;
                        height: 25px;
                    }
                }
            }

            &__detail {
                color: black;
                font-size: 30px;
                max-width: 50%;
                line-height: 2.5vw;
                .text-color-orange {
                    color: $warning;
                    font-family: 'Wreath Halftone Bold';
                    font-weight: 700;
                }
                .text-steel-blue {
                    color: $steel-blue;
                    font-family: 'Century Gothic Bold';
                    font-weight: bold;
                }
                .text-Jivescribe {
                    font-family: 'Century Gothic';
                    color: $primary;
                    font-weight: bold;
                }
            }
            .download {
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 10px;
            }
            .download-app {
                margin-top: 13%;
                font-size: 30px;
                line-height: 2.5vw;
                .text-cornflower-blue {
                    color: $cornflower-blue;
                    font-family: 'Century Gothic';
                    font-weight: bold;
                }
                p {
                    position: relative;
                    color: $black;
                    width: fit-content;
                }
            }
            .link-mobile-app {
                margin-top: 13%;
                display: flex;
                margin-left: 37px;
                img {
                    width: 260px;
                    height: 100px;
                    cursor: pointer;
                }
            }
        }

        .image-mobile {
            position: absolute;
            top: 25%;
            z-index: -1;
            width: 60%;
            right: 20%;
            @media (max-width: $breakpoints-xxl) {
                width: 70%;
                top: 20%;
            }
            @media (max-width: 1700px) {
                top: 27%;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .page-content {
        background-color: $white;
        width: 40%;
        max-height: 100vh;
        margin: 0 auto;
        overflow-y: auto;
        @media (max-width: $breakpoints-xl) {
            width: 100%;
            padding: 3% 10%;
        }
        @media (max-width: $breakpoints-md) {
            padding: 3% 5%;
        }
        .content-container {
            margin: 0 17%;
        }
        @media (max-width: $breakpoints-md) {
            .content-container {
                margin: 0 10%;
            }
        }
        .image-logo {
            margin-top: 20%;
            width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
        .text-color-orange {
            color: $orange-jive;
        }
        .paragraph-extra {
            font-size: 12px;
        }
        .paragraph-medium {
            font-size: 18px;
        }
        .paragraph-main {
            font-size: 36px;
            font-weight: 700;
        }
        .form-group {
            small {
                font-size: 90%;
            }
            input {
                padding-top: 0.6rem;
                padding-bottom: 0.6rem;
            }
        }
        input {
            background-color: $input-background;
        }
        .blue-button {
            font-family: 'Open Sans Bold', sans-serif !important;
            margin: 0 auto;
            width: 100%;
            padding: 0.7rem 0;
            background-color: $primary;
            color: $white;
            font-weight: 500;
            border-radius: 15px !important;
            font-size: 24px !important;
            margin-bottom: 40px;
            letter-spacing: 2px;
            @media (max-width: $breakpoints-md) {
                font-size: 20px !important;
                padding: 0.5rem 0;
            }
            &:hover {
                background-color: $button-hover !important;
            }
        }
        .contact {
            font-family: 'Open Sans SemiBold', sans-serif;
            text-align: center;
            font-size: 18px;
            margin-top: 50px;
            @media (max-width: $breakpoints-xl) {
                font-size: 14px;
            }
            @media (max-width: 280px) {
                height: 100% !important;
                .d-block span {
                    display: inline-block !important;
                    width: 220px;
                    line-height: 20px;
                }
            }
            .text-color-orange {
                color: $warning;
            }
        }
    }
}
