@import '../../common/sass/theme/variables';

.table-component {
    .table-data {
        padding: 0 !important;
        z-index: 0;
        .react-bootstrap-table {
            table {
                font-size: 16px !important;
                margin: 0;
                thead {
                    color: $background-dashboard;
                    font-size: 15px !important;
                    th {
                        font-family: 'Open Sans Bold';
                        font-size: 16px !important;
                        width: 170px;
                        color: $primary !important;
                        padding-left: 1.1rem;
                        line-height: 1;
                        cursor: pointer !important;
                        border-bottom: 1px;
                        border-top: 1px;
                        @media (max-width: $breakpoints-lg) {
                            padding: 0.5rem;
                            width: 150px;
                            &:last-child {
                                width: 100px !important;
                            }
                        }
                        input {
                            width: 16px;
                            height: 16px;
                        }
                        &:last-child {
                            width: 100px !important;
                            border-right: none;
                        }
                        .fa-sort {
                            color: $steel-blue !important;
                        }
                    }
                    .selection-cell-header {
                        width: 10%;
                    }

                    span {
                        float: right;
                        i {
                            color: $background-dashboard !important;
                            font-size: 17px;
                        }
                    }
                }
                tbody {
                    font-family: 'Adobe Clean Regular' !important;
                    td {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        color: $text-extra-color !important;
                        padding-left: 1.1rem;
                        line-height: 1;
                        vertical-align: middle;
                        @media (max-width: $breakpoints-sm) {
                            padding: 0.5rem;
                        }
                        input {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    .no-data-content {
                        margin: 0;
                        color: $light-black;
                        font-style: italic;
                    }
                    .reset-expansion-style {
                        font-family: 'Adobe Clean Regular';
                        background-color: $background-collapse-table;
                        .collapse-row {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        a {
                            font-size: 16px !important;
                            color: $text-extra-color !important;
                            background: #fff;
                        }
                    }
                    .icon-table {
                        padding: 0.5rem;
                        i {
                            font-size: 1.75rem !important;
                            color: $text-extra-color;
                            &:hover {
                                color: $black;
                            }
                        }
                    }

                    .reset-expansion-style {
                        background-color: $background-collapse-table;
                    }
                    .icon-table {
                        padding: 0.5rem;
                        i {
                            font-size: 1.75rem !important;
                            color: $text-extra-color;
                            &:hover {
                                color: $black;
                            }
                            @media (max-width: $breakpoints-sm) {
                                font-size: 1rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .react-bootstrap-table-pagination-total {
        color: $background-dashboard;
    }
    .btn {
        &-add-new {
            font-size: 15px !important;
            background-color: $blue-jive;
            color: $white;
            &:hover {
                color: $white;
                background-color: $button-hover;
            }
        }

        &-delete {
            font-size: 15px !important;
            background-color: $notification-error;
            color: $white;
            &:hover {
                opacity: 0.5;
                color: $white;
            }
        }
        &:disabled {
            opacity: 0.5;
        }
    }

    .active {
        background-color: $button-hover !important;
        color: $white !important;
    }
}
.home-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 37px;
    margin-top: 14px;
    @media (max-width: $breakpoints-lg) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        column-gap: 30px !important;
        .search-component {
            max-width: 100% !important;
            margin-bottom: 20px;
        }
    }
}
.template-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    margin-bottom: 37px;
    margin-top: 14px;
    .template-btn {
        font-family: 'Open Sans Bold';
        padding: 14px 35px;
        border-radius: 10px;
        background-color: $primary;
        color: white;
        font-size: 18px;
        border: none;
        text-align: center;
    }
    @media (max-width: $breakpoints-sm) {
        display: block;
        .template-btn {
            margin-top: 10px;
            padding: 10px 22px;
            border-radius: 10px;
            background-color: $primary;
            color: white;
            font-size: 18px;
            border: none;
            text-align: center;
        }
    }
}
