@import '../../common/sass/theme/variables';
.subHeader {
    max-width: 100vw !important;
    h2,
    .h2 {
        max-width: 100%;
        font-weight: 900 !important;
    }
    .title-page {
        font-family: 'SF UI Text Bold';
        color: $orange-jive;
        min-width: 23rem;
        margin-right: auto;
    }

    .text-size {
        font-size: 24px !important;
    }
    .back-tittle {
        cursor: pointer;
        color: $grey;
        &:hover {
            color: $background-dashboard;
        }
        .back-icon {
            transform: scale(1.2);
        }
    }
    .back-tittle {
        cursor: pointer;
        color: $grey;
        &:hover {
            color: $background-dashboard;
        }
        i {
            font-size: 1.75rem;
        }
    }
}
