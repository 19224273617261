@import '../../../../common/sass/theme/variables';

.button-group {
    margin-top: 45%;
    .btn-welcome {
        border-radius: 15px !important;
        &:hover {
            background-color: $button-hover !important;
        }
    }
}
.text-note {
    color: #FF9900 !important;
}
