@import '../../common/sass/theme/variables';

.forgot-password {
    min-height: 100vh;
    width: 100%;
    background-image: url('../../common/assets/images/background-4.png');
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .row-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5%;
        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 36%;
            .image-doctor {
                margin-top: 20vh;
                max-width: 100%;
                object-fit: cover;
            }
        }
    }

    @media (max-width: $breakpoints-xl) {
        .row-container {
            margin-left: 0;
            margin-right: 0;
            gap: 0;

            .image-doctor {
                display: none;
            }
        }
    }
    .top-space {
        max-width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15vh;
        @media (max-width: $breakpoints-xl) {
            max-width: 100%;
            margin-top: 15vh;
        }
        @media (max-width: $breakpoints-sm) {
            max-width: 100%;
            margin-top: 10vh;
        }
        .card {
            max-width: 100%;
            min-width: 70%;
            border-radius: 30px;
            @media (max-width: $breakpoints-md) {
                width: 80%;
                height: 40%;
                margin-left: 0;
                margin: auto 0;
                .card-body {
                    margin: 0 5%;
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;

                    .text-color-orange {
                        color: $orange-jive;
                    }
                    .backBtn {
                        font-size: 24px !important;
                        margin-left: -17%;
                        font-weight: 500 !important;
                    }
                    .paragraph-extra {
                        font-size: 14px;
                    }
                    .paragraph-medium {
                        font-size: 18px;
                    }
                    .blue-button {
                        font-family: 'Open Sans Bold', sans-serif !important;
                        margin-top: 20px;
                        padding-top: 0.7rem;
                        padding-bottom: 0.7rem;
                        background-color: $blue-jive;
                        color: $white;
                        border-radius: 15px !important;
                        border: none;
                        font-size: 24px !important;
                        @media (max-width: $breakpoints-md) {
                            padding: 0.5rem 0;
                            font-size: 20px !important;
                        }
                        &:hover {
                            background-color: $button-hover !important;
                        }
                    }
                }
            }
            .card-body {
                margin: 0 9%;
                padding-left: 2.5rem;
                padding-right: 2.5rem;

                .text-color-orange {
                    color: $orange-jive;
                }
                .backBtn {
                    font-size: 24px !important;
                    margin-left: -17%;
                    font-weight: 500 !important;
                    &:hover {
                        background: #f0f0f0;
                    }
                }
                .paragraph-extra {
                    font-size: 18px;
                }
                .paragraph-medium {
                    font-size: 18px;
                }
                .paragraph-main {
                    font-family: 'Century Gothic Bold';
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 100%;
                }
                .blue-button {
                    font-family: 'Open Sans Bold', sans-serif !important;
                    padding-top: 0.7rem;
                    padding-bottom: 0.7rem;
                    background-color: $blue-jive;
                    color: $white;
                    border-radius: 15px !important;
                    border: none;
                    font-size: 24px;
                    @media (max-width: $breakpoints-md) {
                        font-size: 20px;
                        padding: 0.5rem 0;
                    }
                    &:hover {
                        background-color: $button-hover !important;
                    }
                }
                .image-logo {
                    object-fit: contain;
                    max-width: 100%;
                    max-height: 100%;
                }
                .form-group {
                    small {
                        font-size: 90%;
                    }
                    input {
                        padding-top: 0.6rem;
                        padding-bottom: 0.6rem;
                    }
                }
                input {
                    background-color: $input-background;
                }
                .text-link {
                    font-size: 0.9rem;
                    cursor: pointer;
                }
                @media (max-width: $breakpoints-sm) {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    .backBtn {
                        font-size: 18px !important;
                        margin-left: -10%;
                    }
                }
            }
        }
    }
}
@media (max-width: $breakpoints-sm) {
    .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
