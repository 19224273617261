@import '../../../../common/sass/theme/_variables';

.statistic {
    display: flex;
    text-align: center;
    justify-content: space-between;
    @media (max-width: $breakpoints-md) {
        flex-direction: column;
    }
    &__item {
        background-color: $white;
        border: 1px solid $silver-gray2;
        padding: 0.5rem 1rem;
        color: $background-dashboard;
        border-radius: 5px;
        font-size: 0.9rem;
        @media (max-width: $breakpoints-md) {
            width: 100% !important;
            margin-bottom: 0.5rem;
        }
        .title {
            margin-top: 0.5rem;
            img {
                position: relative;
                top: -2px;
                left: -5px;
            }
        }
        .content {
            display: flex;
            &__container {
                margin: 1rem 0;
                height: 6rem;
                .content__number {
                    align-items: center;
                    display: flex;
                    justify-content: space-around;
                    height: 80%;
                    font-size: 3rem;
                    font-weight: 700;
                    color: $blue-jive;
                }
                .bd-left {
                    border-left: 2px solid $silver-gray2;
                }
                .bd-right {
                    border-right: 2px solid $silver-gray2;
                }
                .orange-number {
                    color: $orange-jive;
                }
            }
        }
    }
    .start-div {
        width: 26%;
    }
    .middle-div {
        width: 42%;
    }
}
