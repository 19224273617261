@import '../../common/sass/theme/variables';

.form-group {
    .text-color-steel-blue {
        color: $steel-blue;
    }
    .form-input {
        position: relative;
        .password-icon {
            position: absolute;
            font-size: 1.4rem;
            cursor: pointer;
            display: inline;
            padding: 0.35rem;
            transform: scale(0.9, 0.9);
            right: 5px;
        }
        .eye-icon {
            top: 3px;
        }
        .eye-slash-icon {
            top: 0px;
        }
    }
    .text-danger {
        font-size: 90%;
    }
}
.form-group input{
    border-radius: 10px;
}
