@font-face {
    font-family: 'SF UI Text Bold';
    src: url('././SFUIText-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Bold Italic';
    src: url('././SFUIText-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Heavy';
    src: url('././SFUIText-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Heavy Italic';
    src: url('././SFUIText-HeavyItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Light';
    src: url('././SFUIText-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Light Italic';
    src: url('././SFUIText-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Medium';
    src: url('././SFUIText-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Medium Italic';
    src: url('././SFUIText-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Regular';
    src: url('././SFUIText-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Regular Italic';
    src: url('././SFUIText-RegularItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Semibold';
    src: url('././SFUIText-Semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'SF UI Text Semibold Italic';
    src: url('././SFUIText-SemiboldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Century Gothic Regular';
    src: url('././CenturyGothic.woff2') format('woff2'), url('././CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Bold';
    src: url('././CenturyGothic-Bold.woff2') format('woff2'), url('././CenturyGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Regular';
    src: url('././CenturyGothic.woff2') format('woff2'), url('././CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Italic';
    src: url('././CenturyGothic-Italic.woff2') format('woff2'), url('././CenturyGothic-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Medium';
    src: url('././CenturyGothic-Bold.woff2') format('woff2'), url('././CenturyGothic-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Bold Italic';
    src: url('././CenturyGothic-BoldItalic.woff2') format('woff2'),
        url('././CenturyGothic-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Wreath Halftone';
    src: url('././Wreath-HalMed.woff2') format('woff2'), url('././Wreath-HalMed.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Wreath Halftone Bold';
    src: url('././Wreath-HalBol.woff') format('woff2'), url('././Wreath-HalBol.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('././OpenSansCondensed-Light.woff2') format('woff2'),
        url('././OpenSansCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('././OpenSansCondensed-Bold.woff2') format('woff2'), url('././OpenSansCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Medium';
    src: url('././OpenSansCondensed-Medium.woff2') format('woff2'),
        url('././OpenSansCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('././OpenSansCondensed-Regular.woff2') format('woff2'),
        url('././OpenSansCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('././OpenSans-Medium.woff2') format('woff2'), url('././OpenSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Bold';
    src: url('././OpenSansSemiCondensed-Bold.woff2') format('woff2'),
        url('././OpenSansSemiCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans SemiBold';
    src: url('././OpenSansCondensed-SemiBold.woff2') format('woff2'),
        url('././OpenSansCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Light';
    src: url('././OpenSansSemiCondensed-Light.woff2') format('woff2'),
        url('././OpenSansSemiCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Regular';
    src: url('././OpenSansSemiCondensed-Regular.woff2') format('woff2'),
        url('././OpenSansSemiCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('././OpenSans-Regular.woff2') format('woff2'), url('././OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Verdana Regular';
    src: url('././Verdana.woff2') format('woff2'), url('././Verdana-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Verdana Bold';
    src: url('././Verdana-Bold.woff2') format('woff2'), url('././Verdana-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma Regular';
    src: url('././Tahoma.woff2') format('woff2'), url('././Tahoma.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tahoma Bold';
    src: url('././Tahoma-Bold.woff2') format('woff2'), url('././Tahoma-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// Adobe Clean

@font-face {
    font-family: 'Adobe Clean Serif Semibold';
    src: url('././AdobeCleanSerif-Medium.woff2') format('woff2'), url('././AdobeCleanSerif-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean Bold';
    src: url('././AdobeClean-Bold.woff2') format('woff2'), url('././AdobeClean-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean Light';
    src: url('././AdobeClean-Light.woff2') format('woff2'), url('././AdobeClean-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Adobe Clean Regular';
    src: url('././AdobeClean-Regular.woff2') format('woff2'), url('././AdobeClean-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
