@import '../../../../../common/sass/theme/variables';

.modal-content {
    border: unset !important;
    border-radius: 0.6rem !important;
    box-shadow: 5px 5px 7px 0 $box-shadow;
    .modal-header {
        color: $white;
        padding: 20px auto 20px 24px !important;
        border-top-left-radius: calc(0.6rem - 1px) !important;
        border-top-right-radius: calc(0.6rem - 1px) !important;
        .modal-title {
            padding: 20px auto;
            font-family: 'Tahoma Bold' !important;
            font-weight: 700;
        }
        button {
            span {
                color: $white;
            }
        }
    }

    .model-success {
        background-color: $green;
    }
    .model-failed {
        background-color: $error;
    }

    .modal-body {
        padding-bottom: 0;
        padding-top: 1.5rem;
        p {
            font-size: 1rem !important;
            color: $black;
            text-align: center;
            margin-bottom: 0.5rem;
        }
        .sub-content {
            color: $sliver;
            font-weight: normal;
        }
    }
    .modal-footer {
        border: none;
        .success-button {
            background-color: inherit;
            border: none;
            color: $green;
            font-size: 15px !important;
        }
        .failed-button {
            background-color: inherit;
            border: none;
            color: $error;
            font-size: 15px !important;
        }
    }
}
