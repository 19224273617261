@import '../../../../common/sass/theme/variables';

.header-historical {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .download-log {
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 50px;
        .download-log-title {
            font-size: 24px;
            font-family: 'Century Gothic Bold';
            color: #4a86e8;
        }
    }
    @media (max-width: $breakpoints-md) {
        flex-direction: column;
        .download-log {
            margin-bottom: 10px;
        }
    }
    @media (max-width: 400px) {
        .download-log-title,
        .title-page {
            font-size: 20px !important;
        }
        .title-page {
            min-width: 15rem !important;
        }
    }
}

.td-line-height {
    td {
        line-height: 1.5rem !important;
    }
}
.finished-select {
    text-align: center;
    color: #001537 !important;
    background-color: #08e174 !important;
}
.submitted-select {
    color: #001537 !important;
    background-color: #fff3b2 !important;
}

.iconDownloaded {
    width: 25px;
    height: 25px;
    align-items: center;
}

.download-items-container {
    padding: 10px 22px;
    border: 1px solid #6a7891;
    border-radius: 10px;
    color: #001537;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .download-items {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        .file-icon {
            width: 15px;
            height: 20px;
        }
        .title-file-download {
            display: flex;
            flex-direction: column;
            .file-id {
                font-size: 16px;
            }
            .file-name {
                font-size: 12px;
            }
        }
    }
    .download-icon {
        width: 25px;
        height: 25px;
    }
}

.transcription {
    flex-direction: column;
    align-items: start;
    .table-container {
        width: 100%;
        margin-left: 15px !important;
        margin-right: 15px !important;
    }
}

.header-table-arrow {
    width: 20% !important;
    font-family: 'Open Sans Bold' !important;
    font-size: 16px !important;
    color: $primary !important;
}
.table-arrow {
    width: 20% !important;
    font-size: 16px !important;
}
.header-table-done {
    width: 20% !important;
    font-family: 'Open Sans Bold' !important;
    font-size: 16px !important;
    color: $primary !important;
}
.header-table-upload {
    width: 20% !important;
    font-family: 'Open Sans Bold' !important;
    font-size: 16px !important;
    color: $primary !important;
}
.header-table-file {
    width: 30% !important;
    max-width: 250px !important;
    font-family: 'Open Sans Bold' !important;
    font-size: 16px !important;
    color: $primary !important;
}
.table-file {
    width: 30% !important;
    max-width: 250px !important;
    font-size: 16px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.header-table-id {
    width: 20% !important;
    font-family: 'Open Sans Bold' !important;
    font-size: 16px !important;
    color: $primary !important;
}
.table-downloaded {
    width: 172px !important;
}
.table-dateUpload {
    width: 20% !important;
    font-size: 16px !important;
}
.table-id {
    width: 20% !important;
    font-size: 16px !important;
}

.column {
    font-size: 16px !important;
    font-family: 'Adobe Clean Regular' !important;
}
.fileDataName {
    display: flex;
    align-items: center;
    justify-content: start !important;
    gap: 13px;
    img {
        width: 15px;
        height: 20px;
        object-fit: cover;
    }
}
.status-downloaded {
    width: 140px;
    height: 28px;
    object-fit: cover;
}
.nothing-to-show {
    width: 100%;
    display: flex;
}

.expanded {
    font-size: 16px !important;
    font-family: 'Tahoma Regular' !important;
}
.table-rows {
    cursor: pointer;
}
.remove-border-button {
    td {
        border-bottom: none !important;
    }
}
