// 1. Import Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~react-toastify/dist/ReactToastify.css';
@import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// 2. Font
@import './common/assets/fonts/fontStyles.scss';

// 3. Theme
@import './common/sass/theme/theme.scss';

@import '../node_modules/font-awesome/css/font-awesome.min.css';

body,
html {
    font-family: 'Open Sans Regular', 'Adobe Clean Regular', sans-serif;
}

.Toastify {
    &__toast-container {
        max-width: 290px;
        width: 100%;

        @media only screen and (max-width: 480px) {
            padding: 4px !important;
            left: auto !important;
            right: 0 !important;
        }
    }
    &__toast {
        margin-bottom: 0;
        padding: 0 !important;
    }
}
