@import '../../common/sass/theme/_variables.scss';

.confirm-modal-container {
    .cancel-button {
        background-color: $white !important;
        border: 1px solid $blue-jive !important;
        color: $blue-jive !important;
        &:hover{
            background-color: $light-white !important;
        }
    }
    .btn {
        font-size: 15px !important;
        padding: 6px 15px !important;
        min-width: 7rem;
    }
}
.warning-confirm-modal {
    .modal-header{
        background-color: $warning !important;
    }
    .cancel-button {
        background-color: $white !important;
        border: 1px solid $warning !important;
        color: $warning !important;
        &:hover{
            background-color: $light-white !important;
        }
    }
    .confirm-button{
        background-color: $warning !important;
        border: 1px solid $warning !important;
        color: $white !important;
        &:hover{
            background-color: $orange-jive !important;
        }
    }
}
