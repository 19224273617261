@import '../../../../../common/sass/theme/variables';

.filter-box {
    .filter-left-box {
        width: calc(100% - 40px);
    }
    .search-box {
        font-size: 15px !important;
        margin-right: 15px;
        input {
            height: 38px;
        }
        button {
            background-color: $white;
            border: 1px solid #ced4da;
            font-size: 15px !important;
            font-weight: 700;
            color: $background-dashboard;
        }
    }
    .turn-around-time-box {
        #btnTurnAroundTime {
            background: #fff;
            color: $background-dashboard;
            font-weight: 700;
        }
        select {
            text-overflow: ellipsis;
        }
    }

    #btnDownload {
        cursor: pointer;
        height: 38px;
        width: 40px;
        background: url('../../../../../common/assets/images/btn-download.png') right no-repeat;
    }
}
