@import '../../../../common/sass/theme/variables';

.file-template {
    display: flex;
    background: url('../../../../common/assets/images/file-icon.png') no-repeat left;
    background-size: 15px 20px !important;
    height: fit-content;
    padding-left: 1.8rem !important;
    background-position: left 0.4rem center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.mp3-template {
    display: flex;
    background: url('../../../../common/assets/images/mp3Icon.png') no-repeat left;
    height: fit-content;
    padding-left: 1.8rem !important;
    background-position: left 0.2rem center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.collapse-row {
    .button-row {
        padding-left: 2rem !important;
        border-radius: unset;
        border-color: $light-grey;
        background-color: $light-grey;
        color: $text-extra-color;
        width: 20rem;
        text-align: left;
        .icon-row {
            font-size: 16px;
            vertical-align: text-bottom;
        }
    }
    .text-extra-dictation {
        color: $text-extra-color;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
