@import '../../../../../common/sass/theme/variables';

.file-template-box {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    .file-template {
        display: flex;
        background: url('../../../../../common/assets/images/file-icon.png') no-repeat left;
        height: fit-content;
        padding-left: 23px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        word-break: break-word;
    }
}
