@import '../../../../common/sass/theme/variables';

.select-button {
    border: none !important;
    margin: 0 auto;
    max-width: 10rem;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(../../../../common/assets/images/select-arrow.png);
    background-size: 0.6rem 0.6rem;
    background-repeat: no-repeat;
    background-position: right 0.7rem center;
    option {
        background-color: $white;
    }
}
