@import '../../common/sass/theme/_variables.scss';

.menu {
    &-editor {
        background-color: $background-dashboard;
    }
    &-doctor {
        background-color: #fff;
    }
}

.menu {
    width: 21rem;
    height: 100vh;
    box-shadow: 1px 0 0 0 $box-shadow;
    font-size: 16px;
    position: relative;
    @media (max-width: $breakpoints-sm) {
        width: 18rem;
    }
    .menu-icon {
        object-fit: cover;
        position: absolute;
        top: 1rem;
        right: -3rem;
        font-size: 2rem;
        color: $white;
        margin-right: auto;
        cursor: pointer;
        img {
            width: 22px;
            height: 18px;
        }
    }
    .logo-menu {
        display: flex;
        align-items: center;
        img {
            height: 3.5rem;
            margin: auto;
        }
    }
    .info-user {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-extra-color;
        img {
            cursor: pointer;
        }

        &__avatar {
            cursor: pointer;
            width: 6rem;
            height: 6rem;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid $white;
            @media (max-width: $breakpoints-sm) {
                width: 5rem;
                height: 5rem;
            }
        }
        &__name {
            margin-top: 10vh;
            color: $primary;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .avatar-user img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 50%;
            }

            span {
                font-size: 0.9rem;
            }
            p {
                font-family: 'Century Gothic Bold';
                width: 12rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                @media (max-width: $breakpoints-sm) {
                    width: 10rem;
                }
            }
            &-name {
                line-height: 30px;
            }
        }
    }
    .option {
        &__text {
            color: $text-extra-color;
        }
    }
    .text-active {
        color: $warning;
    }
    .options-sidebar {
        font-family: 'Open Sans Regular' !important;
        flex-direction: column;
        max-height: 50%;
        overflow-y: auto;
        .option {
            padding: 10px 0 !important;
            display: flex;
            align-items: center !important;
            img {
                width: 24px;
                height: 21px;
                object-fit: scale-down;
            }
        }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .options-sidebar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .options-sidebar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}
.change-avatar-modal {
    .modal-header {
        background-color: $white !important;
        color: $sliver !important;
    }
    .modal-body {
        display: flex;
        .new-avatar {
            width: 10rem;
            height: 11rem;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .input-avatar {
            width: calc(100% - 11rem);
            margin-left: 1rem;
            input {
                display: none;
            }
            .error-validate {
                width: 100%;
            }
            label {
                text-align: center;
                width: 7.5rem;
                display: block;
                padding: 7px;
                cursor: pointer;
                border-radius: 4px;
                background-color: $white;
                border: 1px solid $sliver;
                color: $sliver;
                font-weight: 600;
                &:hover {
                    background-color: $light-white;
                }
            }
        }
    }
}
