.title-user-template {
    position: relative;
    padding-left: 18px;
    cursor: pointer;

    .go-back {
        width: 16px;
        height: 30px;
        background: url('../../../common/assets/images/go-back-icon.png') no-repeat;
        left: 0;
        top: 7px;
        position: absolute;
    }
}
