@import '../../common/sass/theme/variables';

.pw-rules {
    &__title {
        letter-spacing: 0;
        font-size: 1rem;
        font-weight: 600;
        color: $auth-pw-rules-title;
    }
    &__content {
        .pw-rule {
            line-height: 1.25rem;
            .fa-circle {
                font-size: 0.3rem;
                position: relative;
                bottom: 0.21rem;
            }
        }
    }
    .paragraph-medium {
        font-size: 14px;
    }
}
