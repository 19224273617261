@import '../../../common/sass/theme/variables';

.datePicker-box {
    margin-left: 15px;
    .input-group {
        border-bottom-left-radius: 0.25rem;
        border-top-left-radius: 0.25rem;
        .input-group-prepend {
            background-color: $background-dashboard;
            border-bottom-left-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
            #btnFilterDatePicker {
                border-bottom-left-radius: 0.25rem;
                border-top-left-radius: 0.25rem;
                height: 38px;
                width: 50px;
                background: url('../../../common/assets/images/calendar-icon.png') center no-repeat;
                background-size: 20px 20px;
            }
        }
        #filterDatePicker {
            cursor: pointer;
            text-align: center;
            background: $white;
            text-overflow: ellipsis;
        }
    }
    .date-picker-item-box {
        width: 100%;
        position: absolute;
        z-index: 1;
        margin-top: 10px;
        .date-picker-header {
            color: $white;
            background-color: $background-dashboard;
            height: 38px;
            display: flex;
            font-size: 16px;

            justify-content: space-between;
            #btnPrevYear {
                width: 25px;
                cursor: pointer;
                height: 38px;
                background: url('../../../common/assets/images/btn-prev-year.png') center no-repeat;
            }
            #textYear {
                padding-top: 10px;
            }
            .text-year {
                opacity: 0.5;
            }
            #btnNextYear {
                width: 25px;
                cursor: pointer;
                height: 38px;
                background: url('../../../common/assets/images/btn-next-year.png') center no-repeat;
            }

            .c-no-drop-force {
                cursor: no-drop !important;
            }
        }
        .date-picker-content {
            padding: 5px;
            background-color: $white;
            div {
                margin-top: 5px;
                min-height: 38px;
                padding-left: 20px;
                padding-top: 10px;
                background-color: $background-page;
            }
            div:hover {
                border: 1px solid $background-dashboard;
                cursor: pointer;
            }
            div.active {
                background-color: $background-dashboard;
                color: $white;
            }
        }
        .date-picker-footer {
            background-color: $background-dashboard;
            height: 28px;
        }
    }
}
