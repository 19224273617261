@import '../../../../../common/sass/theme/_variables.scss';
.add-assignee-modal {
    .modal-body {
        padding: 1rem 1.5rem;
        .form-label {
            color: $background-dashboard;
            font-size: 0.9rem;
        }
        .label--required::after {
            content: '*';
            color: $error;
        }
        .input-select {
            font-size: 0.9rem !important;
            padding: 10px;
            option {
                font-size: 0.9rem;
                height: 1.5rem;
            }
        }
        .time-block {
            display: flex;
            flex-wrap: wrap;
            .form-group {
                display: flex;
                margin-right: 2rem;
                flex-direction: column;
                .input-mask {
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    border-radius: 5px;
                    border: 1px solid $silver-gray2;
                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
    .modal-footer {
        padding: 1rem 1.5rem;
        .add-button {
            font-size: 15px !important;
            background-color: $blue-jive;
        }
    }
}
