@import '../../../common/sass/theme/variables';

.reset-password-responsive {
    @media (max-width: $breakpoints-sm) {
        height: fit-content;
    }
    .top-space {
        margin-top: 10vh;
    }
}
.contact{
    font-size: 14px;
}
.text-note {
    color: #ff9900 !important;
}
