@import '../../common/sass/theme/variables';

.layout-app {
    display: flex;
    .menu-app {
        width: 21rem;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        @media (max-width: $breakpoints-xl) {
            width: 100%;
        }
        .menu-content {
            position: absolute;
            width: 21rem;
            left: -22rem;
            transition: transform 0.2s ease;
            will-change: transform;
            transform: translateX(0);
            display: flex;
            @media (max-width: $breakpoints-sm) {
                width: 18rem;
                left: -18rem;
            }
            &__show {
                transform: translateX(22rem);
                @media (max-width: $breakpoints-sm) {
                    transform: translateX(18rem);
                }
            }
        }
        .layer {
            background-color: $layer-shadow;
            width: 100vw;
            height: 100vh;
        }
    }
    .layout-content {
        transition: all 0.2s ease;
        width: 100%;
        background-color: #f5f9fd;
        .children-padding {
            width: 100%;
            min-height: 100vh;
            background-color: #f5f9fd;
            padding-left: 3%;
            padding-right: 3%;
            @media (max-width: $breakpoints-sm) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    .resize {
        width: calc(100% - 21rem);
        transition: all 0.2s ease;
        will-change: transform;
        transform: translateX(21rem);
    }
}
