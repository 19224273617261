@import '../../../../common/sass/theme/variables';

.table-toolbar {
    .input-group {
        width: 100%;
    }
    .btn-filter {
        background-color: $primary;
        font-size: 15px !important;
        font-weight: 100;
        color: white;
        border-radius: 10px;
        &:hover {
            background-color: transparent;
            color: black;
        }
    }
    #filter-select {
        border-radius: 10px;
        font-size: 15px !important;
        width: 8rem;
        height: 30px;
        border: 1px solid #b5bec6;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 1.75rem;
        padding-left: 1rem;
        background-color: $white;
        background-image: url(../../../../common/assets/images/select-arrow.png);
        background-size: 0.6rem 0.6rem;
        background-repeat: no-repeat;
        background-position: right 0.7rem center;
        cursor: pointer;
    }
}
.input-group {
    .MuiInputBase-root {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        font-size: 16px !important;
        width: 12rem;
        height: 35.83px;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 1.75rem;
        padding-left: 1rem;
        background-color: $white;
        &:focus {
            outline: none;
        }
    }
    .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
        margin-top: 20px;
    }

    #demo-select-small-label {
        background: #f5f9fd;
    }
    .MuiPaper-root {
        margin-top: 20px !important;
    }

    select option:checked {
        color: #b5bec6;
        padding: 6px 16px;
        text-overflow: ellipsis;
        margin-bottom: 10px;
    }
    *:focus {
        outline: none;
    }
    .option-selected {
        color: #b5bec6 !important;
    }
    select option:first-child {
        color: #b5bec6;
    }
    @media (max-width: 400px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.MuiPaper-root {
    width: 14rem;
    margin-top: 18px !important;
    border-radius: 15px !important;
    transform: translateX(-17px) !important;
    ul > li:hover {
        color: #fff;
        background-color: $primary;
    }
}
.MuiFormControl-root:focus {
    outline: none !important;
}

.filterIcon {
    width: 7px;
    height: 10px;
    object-fit: cover;
    margin-right: 10px;
}
