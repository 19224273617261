@import '../../../../common/sass/theme/variables';

.toggle-container {
    display: flex;
    justify-content: center;
}

.toggle {
    background: orange;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    border-radius: 32px;
    padding: 8px 4px;
    width: 150px;
    &:hover {
        background-color: orange;
    }
    &__rounded {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: white;
    }
    &__status {
        color: white;
    }
    &.activated {
        background-color: $dodger-blue;
        &:hover {
            background-color: $button-hover;
        }
    }
    &.deactivated {
        flex-direction: row-reverse;
        background-color: $error;
        &:hover {
            background-color: $notification-error;
        }
    }
}
@media screen and (max-width: $breakpoints-sm) {
    .toggle {
        justify-content: center;
        &__rounded {
            display: none;
        }

        &__status {
            text-align: center;
        }
    }
    tr th:last-child {
        width: 150px !important;
    }
}
