@import '../../../common/sass/theme/_variables.scss';

.change-password-background {
    background-image: none;
    background-color: unset;
    min-height: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    .top-space {
        max-width: 100%;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        .title-change-password {
            font-family: 'Century Gothic';
            font-size: 36px;
            padding: 1.5rem 0 1rem 0;
        }
        .card-body {
            margin: 0 !important;
            padding: 0px 5rem !important;
        }
        @media (max-width: 400px) {
            padding-left: 0;
            padding-right: 0;
            .card-body {
                margin: 0 !important;
                padding: 0px 1rem !important;
            }
        }
    }
}

.header {
    .topbar-icon {
        position: absolute;
        top: 1rem;
        font-size: 2rem;
        color: $white;
        margin-right: auto;
        cursor: pointer;
    }
}
