@import '../../common/sass/theme/variables';

.header {
    padding-right: calc(3% + 15px);
    &-editor {
        background-color: $background-dashboard;
    }
    &-doctor {
        background-color: #fff;
    }
    height: 4rem;
    z-index: 100;
    box-shadow: 0 1px 0 0 $box-shadow;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    .navbar {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .dropdown {
            margin-left: auto;
            font-size: 1rem;
            display: flex;
            min-width: 200px;
            max-width: 250px;
            a {
                font-weight: 700;
                &::after {
                    content: '';
                    position: relative;
                    top: 7px;
                    right: -1rem;
                    font-size: 1.3rem;
                }
                @media (max-width: $breakpoints-md) {
                    margin-right: 1rem;
                }
            }
            #user-options {
                padding-right: 15px;
                color: $primary;
                min-width: 150px;
                display: flex;
                justify-content: end;
            }
            #user-options-editor {
                color: white;
                min-width: 150px;
                display: flex;
            }
            .dropdown-menu {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                width: 100%;
                padding: 0;
                margin-top: 9px !important;
                border: none;
                border-radius: unset;
                .dropdown-item {
                    border-bottom: 1px solid $box-shadow;
                    color: $text-extra-color;
                    text-align: left;
                    background-color: $white;
                    padding: 1rem 1.5rem;
                    display: flex;
                    font-size: 0.9rem;
                    font-weight: 100;
                    .item-icon {
                        position: relative;
                        top: -2px;
                        left: -3px;
                        img {
                            width: 20px;
                            height: 20px;
                            object-fit: cover;
                        }
                    }
                    &:hover {
                        background-color: $chosen-option-sidebar;
                        transition: 0.2s;
                    }
                }
            }
        }
        .dropdown-sort-name {
            display: flex;
            justify-content: end;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;

            .dropdown-menu {
                left: 20;
            }
        }
    }
}
