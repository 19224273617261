.backBtn {
    color: #051a3b;
    border: none;
    padding: 13px 25px;
    margin-top: 60px;
    background: #fff;
    font-size: 20px;
    transform: translateX(-80%);
}
@media (max-width: 1600px) {
    .backBtn {
        transform: translateX(-30%);
        font-size: 24px;
        font-weight: 500 !important;
    }
}
