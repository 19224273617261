@import './variables';

body {
    font-family: 'Open Sans Regular', sans-serif;
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $white;
    color: $sliver;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: calc(0.1rem - 1px);
    background-color: $background-page;
}

.form-control.error {
    border-color: $error !important;
}

.form-control:focus {
    background-color: $white;
    outline: none;
    border-color: $grey;
    box-shadow: none;
}

.form-label {
    color: $black;
}

input,
button {
    &:focus {
        box-shadow: none !important;
    }
}

.dropdown-item {
    &:active,
    &:hover {
        text-decoration: none;
        background-color: transparent;
    }
}

.dropdown-menu {
    font-size: 13px;
    height: auto;
}

ul {
    list-style: none;
    padding-left: 0;
}

a {
    color: $black;
    text-decoration: none;
    &:focus,
    &:hover {
        color: $grey;
        text-decoration: none;
    }
}

.black-button {
    background-color: $black;
    border-color: $black;
}

.black-color {
    color: $black;
}

.verification-container {
    width: 100%;
    display: flex !important;
    flex-wrap: wrap;
    input {
        width: 42px;
        height: 42px;
        border: 1px solid $silver-gray2;
        margin: auto;
        margin-top: 1rem;
        text-align: center;
        border-radius: 0.25rem;
        &:focus {
            background-color: $white;
            outline: none;
            border-color: $grey !important;
        }
    }
    &.error {
        input {
            border-color: $error;
        }
    }
}

.hidden {
    display: none;
}

.finished-select {
    background-color: $background-finished-select !important;
}

.submitted-select {
    background-color: $background-submitted-select !important;
}

.cancel-select {
    background-color: $background-cancel-select !important;
}

.in-progress-select {
    background-color: $background-in-progress-select !important;
}

.unassigned-select {
    background-color: $background-unassigned-select !important;
}

.item-table {
    cursor: pointer;
    &:hover {
        background-color: $light-sliver2;
    }
}
