.file-template-box {
    padding-left: 5px;
    cursor: pointer;
    .file-template {
        background: url('../../../../common/assets/images/file-icon.png') no-repeat left;
        height: fit-content;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}
