@import '../../../common/sass/theme/variables';

.delete-icon {
    text-align: center;
    cursor: pointer;
    img {
        transform: scale(0.9);
        margin: auto;
    }
}

.input-template-file {
    font-family: 'Open Sans Bold';
    font-size: 18px;
    input {
        display: none;
    }
    .error-validate {
        width: 100%;
    }
    label {
        width: fit-content;
        display: block;
        padding: 13px 35px;
        cursor: pointer;
        border-radius: 4px;
        background-color: $primary !important;
        border: none;
        color: $white;
        font-weight: 400;
        &:hover {
            background-color: $button-hover;
        }
    }
}

.disable-button-upload {
    pointer-events: none;
    cursor: default;
    label {
        background-color: $blue-light;
        &:hover {
            background-color: $blue-light;
        }
    }
}

.text-body {
    font-size: 16px !important;
    margin: 21px 16px !important;
}
.label-add-assignee {
    color: $steel-blue !important;
    font-size: 18px !important;
}

.modal-footer {
    gap: 10px !important;
}

.cancel-button {
    font-family: 'Open Sans Bold';
    background-color: #6a7891 !important;
    padding: 13px 40px !important;
}

.save-button {
    font-family: 'Open Sans Bold';
    color: #122239;
    background-color: $primary !important;
    padding: 13px 49px !important;
}
.cancel-button {
    background-color: #00000029 !important;
    padding: 13px 40px !important;
    color: #122239 !important;
}

.file-template-upload {
    display: flex;
    background: url('../../../common/assets/images/file-icon.png') no-repeat left;
    background-size: 15px 20px !important;
    height: fit-content;
    padding-left: 2rem !important;
    background-position: left 0.4rem center;
    border-radius: unset;
    border-color: $light-grey;
    color: $text-extra-color;
    width: 75%;
    word-break: break-all;
    line-height: 1.5;
    text-align: left;
    .icon-row {
        font-size: 16px;
        vertical-align: text-bottom;
    }
}

.delete-template-confirm-modal {
    p {
        font-size: 1.1rem;
        color: $black;
    }
    .sub-text {
        font-size: 0.9rem !important;
    }
    .modal-header {
        background-color: $notification-error !important;
        color: $white;
    }
    .delete-button {
        background-color: $notification-error !important;
        border: unset;
        height: 36px;
        &:hover {
            background-color: $error !important;
        }
    }
    .no-button {
        background-color: $white !important;
        border: 1px solid $notification-error !important;
        color: $notification-error !important;
        &:hover {
            background-color: $light-white !important;
        }
    }
}

.update-status-confirm-modal {
    &__activated {
        .modal-header {
            background-color: $notification-error !important;
        }
        .update-status-button {
            background-color: $error !important;
            border: unset;
            &:hover {
                background-color: $notification-error !important;
            }
        }
        .cancel-button {
            background-color: $gray-400 !important;
            border: 1px solid $gray-400 !important;
            color: $text-extra-color !important;
            &:hover {
                background-color: $light-white !important;
            }
        }
    }
    &__deactivated {
        .modal-header {
            background-color: $blue-bold !important;
        }
        .update-status-button {
            background-color: $blue-bold;
            border: unset;
            &:hover {
                background-color: $primary;
            }
        }
        .cancel-button {
            background-color: $gray-400 !important;
            border: 1px solid $gray-400 !important;
            color: $text-extra-color !important;
            &:hover {
                background-color: $light-white !important;
            }
        }
    }
}
.empty-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    .empty-element {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 40%;
        .empty-template {
            width: 70%;
            height: 50%;
            object-fit: cover;
            margin-bottom: 50px;
        }
        p {
            font-size: 18px;
        }
    }
    // .contained-modal-title-center
    #text-26 {
        font-family: 'Tahoma Bold' !important;
        font-size: 26px;
    }
}
