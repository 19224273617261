@import '../../../../common/sass/theme/variables';

.form-check-input {
    cursor: pointer;
    margin-top: 0.15rem !important;
    width: 15px;
    height: 15px;
}

.form-check-label {
    cursor: pointer;
    font-size: 16px;
    margin-left: 10px;
}

.lb-forgot-password {
    color: $sliver !important;
    cursor: pointer;
    font-size: 16px;
    &:hover {
        color: $black !important;
    }
}

.lb-help {
    color: $sliver;
    font-weight: 500;
    text-align: center;
    word-break: break-word;
}

.login-responsive {
    @media (max-width: $breakpoints-sm) {
        height: fit-content;
    }
    .top-space {
        margin-top: 10vh;
    }
}

.login-background {
    background-image: url('../../../../common/assets/images/background-1.jpg');
}

.login-card {
    width: 600px;
}

.login-card-container {
    flex-direction: column;
}