@import '../../common/sass/theme/variables';

.form-group {
    .form-select-height {
        height: calc(1.5em + 0.75rem + 2px);
    }
    .text-danger {
        font-size: 90%;
    }
}
