@import '../../../common/sass/theme/_variables.scss';

.turn-around-time-page {
    .content-page {
        display: flex;
        justify-content: space-between;
        @media (max-width: $breakpoints-md) {
            flex-direction: column-reverse;
        }
        .table-items {
            width: 63%;
            height: 100%;
            position: relative;
            @media (max-width: $breakpoints-md) {
                width: 100%;
            }
            table {
                thead {
                    tr {
                        font-size: 0.9rem;
                        th {
                            &:focus {
                                outline: none;
                            }
                            .fa {
                                transform: scale(1, 1.1);
                                cursor: pointer;
                            }
                            &:last-child {
                                border-right: unset;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        color: $background-dashboard;
                        td:last-child {
                            border-left: 1px solid $border-table;
                        }
                    }
                }
            }
            &.full-with{
                width: 100%;
            }
        }
        .add-new-item {
            width: 35%;
            @media (max-width: $breakpoints-md) {
                width: 100%;
                margin-bottom: 1rem;
            }
            .card {
                color: $white;
                overflow: hidden;
                border-radius: 5px;
                .card-title {
                    height: 3rem;
                    background-color: $blue-jive;
                    p {
                        padding-left: 1.5rem;
                        line-height: 3rem;
                        font-size: 0.9rem;
                    }
                }
                .form-control {
                    padding: 0.3rem;
                    font-size: 1rem !important;
                    margin-left: 1.5rem;
                    margin-top: 1rem;
                    width: calc(100% - 3rem);
                    &:focus {
                        border-color: $blue-jive;
                    }
                }
                .submit-form {
                    width: 100%;
                    text-align: center;
                    .custom-button {
                        font-size: 0.9rem !important;
                        padding: 0.4rem 4rem;
                        background-color: $blue-jive;
                        @media (max-width: $breakpoints-md) {
                            width: calc(100% - 3rem);
                            padding-top: 0.5rem;
                            padding-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
.delete-icon {
    text-align: center;
    cursor: pointer;
    img {
        transform: scale(0.9);
        margin: auto;
    }
}
.delete-icon--fade {
    opacity: 0.5;
}

.turn-around-time-confirm-modal {
    p {
        font-size: 1.1rem;
        color: $black;
    }
    .modal-header {
        background-color: $error;
        color: $white;
    }
    .delete-button {
        background-color: $error;
        border: unset;
        height: 36px;
        &:hover {
            background-color: $notification-error;
        }
    }
}
